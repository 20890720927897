
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

html {
  // background-color:$body-background;
  background-image: linear-gradient($grey-light, $white);
  background-repeat:repeat-x;
  background-size:100%;
  &.page-header-full-width{
    background:white;
    #page{
      box-shadow:none;
      padding-top:0;
    }
    // Fix header image overlay (scrollbar reduces the 100vw)
    .header-image-overlay--right .header-image-overlay__content{
      right:30px;
    }
  }
}

body {
  color: $text-color;
  background:transparent;
  overflow:hidden;
}

// Responsive Images
img{
  // we define it here, cause the global.base will be loaded for the wysiwyg editor also - so this attributes will destroy image resizing functions
  width:auto;
  height:auto;
}

// =============================================================================
//  Wrappers
// =============================================================================

.page{
  box-shadow: 0 0 200px #ccc;
  @if($full-width-page == false){
    background:$page-background;
    margin:0 auto;
  }
}

.page-header {
  background-color:$page-background;
  text-align:center;
  > .inner{
    @include media-breakpoint-up(lg){
      padding:$space-medium-v 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

.page-content{
  // overflow:hidden; <-- causes various problems.
}

.page-footer{
  @include cutline('top');
}

// =============================================================================
//  Branding
// =============================================================================

// Wraps all the branding elements, logo, name and slogan
.branding {
  // display:none; // logo is already present inside the headroom bar on small devices
  max-width:$logo-max-width;
  margin:0;
  padding-top:1rem;
  padding-bottom:1rem;
  // @include media-breakpoint-up($headroom-docking-permanent-breakpoint){
  //   display:block;
  // }
  @include media-breakpoint-up(lg){
    padding-top:0;
    padding-bottom:0;
  }
}

// Logo
.page-logo {
  padding: 0;
  text-align:center;
  a{
    display:block;
  }
  img {
    vertical-align: bottom;
  }
}

// Site name shown in the header
.page-name {
  margin: 0;
}

// Site slogan shown in the header
.page-slogan {
  margin: 0;
}

// =============================================================================
//  Animations (CSS)
// =============================================================================

// Using Animate.css library https://daneden.github.io/animate.css/ > see libraries/animate.scss to enable specific animations
// To use the animations simply include the mixin animated and the animation name eg.: @include animation-name(bounce);

// Some custom animations
// -- Always fade colors
*,
*::before,
*::after{
  transition-property: color, background-color, border-color;
  transition-duration: $transition-duration-default;
}

// -- Pulsating Glow
@keyframes pulsating-glow {
  0% { box-shadow:0 0 3px $danger; }
  50% { box-shadow:0 0 10px $danger; }
  100% { box-shadow:0 0 3px $danger; }
}

// -- Dragging complete (eg. Sticky Tables)
@keyframes dragging-complete{
  0% { background:$info; }
  100% { background:$white; }
}

// -- Swipe In (eg. mobile menu trail)
@keyframes swipe-in{
  0%   {
    transform: translate(-150px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

// =============================================================================
//  Regions
// =============================================================================

.region {
  // All Regions
  &-inner {
    // Inner Wrapper
  }
}

.region-header {
  // Contents
  // -- Secondary Head Menu
  .menu{
    @include horizontal-menu();
    @include tiny-menu('horizontal');
  }
  // -- Search
  .block-search{
    margin-bottom:0;
    margin-top:15px;
    @if($headroom-searchblock == true){
      display:none;
    }
    @include media-breakpoint-up(md){
      float:right;
      @if($headroom-searchblock == true){
        display:block;
      }
    }
  }
}

.region-header-slide{
  clear:both;
  // TODO: Better make this a mixin "span page outer space.. something"
  margin-left: ceil(($page-outer-grid + 1) / -2);
  margin-right: ceil(($page-outer-grid + 1) / -2);
  @include media-breakpoint-up(lg){
    margin-left:($page-outer-grid + 1) * -1;
    margin-right: ($page-outer-grid + 1) * -1;
  }
}
.region-header-slide-full{
  img{
    max-width:none !important;
    width:100% !important;
  }
}

.navigation-bar-wrapper{
  clear:both;
  background:$highlight;
  margin-left: $page-outer-grid * -1;
  margin-right: $page-outer-grid * -1;
  padding-left: $page-outer-grid;
  padding-right: $page-outer-grid;
  > .region-inner{
    max-width:$page-max-width;
    margin:0 auto;
  }
}
.region-navigation-bar{
  > .region-inner{
    @if $navbar-searchblock{
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before,
      &::after{
        display:none !important;
      }
      .block{
        &.block-search-form,
        &.block-search,
        &.block-block-3{ // block-3 = Offcanvas Trigger
          @include media-breakpoint-up(sm){
            float:right; // flex fallback
            margin-right:0;
          }
        }
      }
    }
  }
}
.flexbox,
.flexboxlegacy{
  .region-navigation-bar{
    > .region-inner{
      &::after{
        // Remove clearfix as this fucks flexbox layout (but we need it for the fallback)
        display:none;
      }
    }
  }
}

.region-help {
  .block{
    @include box-style('info');
  }
}

.sidebar{

}

.region-sidebar-first {
  // Left Sidebar
}

.region-sidebar-second {
  // Right Sidebar
  .block-title{
    background:white;
    color:$grey_dark;
    border-radius:$border_radius_small $border_radius_small 0 0;
    padding:8px $thin_grid;
    font-size:$font_normal;
    font-weight:$font_weight_bold;
    border:1px solid $grey_light;
    margin-bottom:$thin_grid;
    + .block-content{
      border-radius:0 0 $border_radius_small;
      border-width:0 1px 1px 1px;
      margin-top:-$thin_grid;
    }
  }
  .block-content{
    border-width:1px;
    border-style:solid;
    border-color:$grey_light;
    border-radius:$border_radius_small;
    padding:$thin_grid;
    @include clearfix-after;
  }
  .block{
    margin-bottom:$grid_vertical;
    &:hover{
      .block-title{
        background-color:$grey_lighter;
      }
    }
  }
  // OWL Slider Bugfix (right side gets cut off)
  .owl-item{
    > *{
      width:99%;
    }
  }

  // Angebote
  .node-angebot{
    .field-name-field-subtitle,
    .field-name-body{
      font-size:$font-small-px;
    }
  }
}

// Bottom (full width)
.region-bottom{
  @include cutline(top, $space-large-v);
}

.region-footer {
  > .region-inner{
    padding:0;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    @include media-breakpoint-up(lg){
      flex-direction:row;
    }
    &::before,
    &::after{
      display:none !important;
    }
  }
  // Contents
  .menu{
    display:flex;
    flex-direction:column;
    @include media-breakpoint-up(md){
      flex-direction: row;
    }
  }
}

// =============================================================================
//  Block (& Panel Pane) Styling
// =============================================================================

.block {
  // All Blocks
  &-inner {
    // Inner Wrapper
  }
  &-title {
    margin-top: 0;
  }
  &-content {
    p:last-child{
      margin-bottom:0;
    }
  }
}

.panel-pane {
  // All Panes
  margin-bottom:$space-thin-v;
  .pane-title {
    margin-top: 0;
  }
  .pane-content {
    p:last-child{
      margin-bottom:0;
    }
  }
}

// Formatter
.block,
.panel-pane{
  &--right{
    // Align flex items right
    margin-left:auto;
    margin-right:3px;
    float:right; // fallback - flexbox will ignore this
  }
}

// Block Specific

// -- Shariff
.block-shariff-shariff-block{
  margin-top:1rem;
}

// -- Header Image
.views-field-field-headerimage {
  line-height: 0;
}

.block-views-header-slideshow-block {
  line-height: 0;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.view-header-slideshow {
  position: relative;
  .node{
    margin-bottom:0;
  }
  img {
    @if $full-width-page {
      // Show slideshow image on 100% width - even if its overscaled.
      width: 100% !important;
      min-height: 100px;
      max-height: 80vh;
      max-width: none;
      object-fit: cover;
      object-position: 50% 68%;
    }
  }

  div[class*="item-"] {
    // non js classes! the hide thing beyond may be deprecated (with .owl-item)
    display: none; // hide on load
  }

  div.item-0 {
    display: block; // show first image also if js not ready
  }

  // Show items after owl-slider is initialized
  .owl-item {
    div[class*="item-"] {
      display: block;
    }
  }

  .view-empty {
    padding: 0 !important;
    background: none !important;
    border: 0 none;
    border-radius: 0;
  }
}

// -- Overlay
.header-image-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  &__content {
    position: absolute;
    bottom: $space-thin-v;
    background-color: $white-glass;
    padding: $space-thin-v $space-thin-h;
    max-width: 400px;
    p:empty{
      display: none;
    }
    h2, h3, h4{
      margin-top:0;
      margin-bottom:1rem;
    }
    .field-name-body{
      margin-bottom:.5rem !important;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: $space-thin-v;
  }

  &--left {
    .header-image-overlay__content {
      left: $space-thin-h;
    }
  }

  &--right {
    .header-image-overlay__content {
      right: $space-thin-h;
    }
  }
}

.header-image__overlay-link {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-indent: -9000px;
}

// -- -- Header Image BLOCK "Matching Images" (node edit)
.header-image--matching-images {
  @include box-style;
}

// =============================================================================
//  Field Styling (Output)
// =============================================================================

.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0;
}

.field-label-inline{
  display:flex;
  // Remove clearfix
  &::before,
  &::after{
    display:none;
  }
  .field-label,
  .field-items,
  .field-item{
    display:inline;
  }
}

// Basic Field Types
// -- Body
.field-name-body{
  @include clearfix-after;
  h2,
  h3{
    &:first-child{
      margin-top:0;
      padding-top:0;
    }
  }
}
// -- Image
.field-type-image {
  .colorbox{
    cursor:zoom-in;
  }
  .field-item{
    line-height:0;
    text-align:center;
  }
}

// -- -- Titleimage
.field-name-field-titelbild{
  margin-bottom:$grid-vertical;
}
// -- -- Gallery
.field-name-field-bilder,
.field-name-field-galerie{
  margin-top:$grid-vertical;
  margin-bottom:1em;
  .field-item{
    margin-bottom:$grid;
    a{
      overflow:hidden;
      display:block;
    }
    img{
      transition-property: transform, opacity;
    }
    &:hover{
      img{
        transform: scale(1.05);
      }
    }
  }
}

// -- Videos
.field-type-video-embed-field{
  margin-bottom:1em;
  .field-item{
    margin-bottom:$grid;
  }
}

// Named (custom) Fields
// -- Subline
.field-name-field-subtitle{
  font-size:$font-size-px;
  font-weight:$font-weight-bold;
  margin-bottom:1em;
  padding-top:.15em;
}
// -- Downloads Anhaenge
.field-name-field-attachments{
  margin-bottom:1.5em;
  .field-items{
    display: flex;
    flex-wrap: wrap;
    margin:0 ($half-grid * -1);
  }
  .field-item{
    @include media-breakpoint-up(md){
      width:50%;
      padding:0 $half-grid;
    }
  }
  .file{
    display:block;
    padding:4px 0;
    margin-bottom:3px;
    &:not(:last-child){
      border-bottom:1px solid $grey;
    }
    a{
      @include btn(ghost);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow:hidden;
      display:block;
    }
  }
}
// Node Custom tiles (field collection)
.field-name-field-custom-tiles{
  @include cutline(bottom, 1rem, 1rem);
  > .field-items{
    > .field-item{
      display:flex;
      flex-direction: column;
      margin-bottom:1rem;
      > .field-name-field-image,
      > .field-name-field-titelbild{
        margin-bottom:.5rem;
      }
      > .field-type-text-long{
        line-height:$line-height-medium;
        h2, h3, h4{
          margin-top:0;
          margin-bottom:.5rem;
        }
      }
      > .btn-inside{
        padding-top:1rem;
        margin-top:auto;
      }
    }
  }
}

// =============================================================================
//  Search (Page)
// =============================================================================

.custom-search-filter{
  ul{
    list-style:none;
    margin:0;
    padding:0;
  }
  li{
    list-style:none;
    margin:0;
    padding:0;
    display:inline-block;
  }
  a{
    @include btn('ghost');
  }
}

// =============================================================================
//  Node Styling
// =============================================================================

// TODO-Vorlage: This was a project related thing.. remove it.
@mixin node-header{
  text-align:center;
  .page-title{
    display:inline-block;
    line-height:$line-height-medium;
  }
  .field-name-field-subtitle{
    display:inline-block;
    font-size:$h1-font-size;
    line-height:$line-height-medium;
    margin-left:$thin-grid;
  }
}


.node {
  margin-bottom: 20px;
  &__header{
    @include node-header;
    &--has-subline{
      margin-bottom: ceil($h1-font-size * .67);
    }
  }
  .node-title {
    margin: 0;
  }
  &.view-mode-full{
    .field-name-body{
      margin-bottom:$space-medium-v;
    }
  }
}

// -- Author Info
footer.submitted{
  background: none repeat scroll 0 0 $grey-light;
  clear: both;
  color: $grey;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top;
  .user-picture {
    border: 5px solid $grey-light;
    background:$white;
    float: left;
    line-height: 0;
    margin-right:15px;
    margin-left: -15px;
    margin-top: -10px;
  }
}

// Node specific
// -- Produkte & Angebote
.angebot-content,
.product-content{
  &__right{
    text-align:left;
    .field-name-field-produkt-tags{
      padding-top:.5rem;
    }
  }
}
.angebot-content{
  > .subline{
    // fix gap between h1 and subline
    margin-top: ($h1-font-size * .67) * -1; // .67 = margin-bottom of h1

  }
}
.group-product-techndaten{
  margin-bottom:1.5rem;
  > .fieldset-wrapper{
    > .field:not(.field-name-field-product-technd-custom){
      margin-bottom:0;
      border-bottom:1px solid $grey_light;
      margin-bottom:2px;
      @include media-breakpoint-up(md){
        display:flex;
      }
      &:last-child{
        border-bottom:0;
      }
      .field-label{
        float:none;
        @include media-breakpoint-up(md){
          width:35%;
        }
      }
      .field-items{
        float:none;
        @include media-breakpoint-up(md){
          width:65%;
        }
      }
    }
  }
}
// Inconsistent markup - do the same stuff for the additional values field.
.field-name-field-product-technd-custom > .field-items{
  > .field-item{
    margin-bottom:0;
    border-bottom:1px solid $grey_light;
    margin-bottom:2px;
    @include media-breakpoint-up(md){
      display:flex;
    }
    &:last-child{
      border-bottom:0;
    }
    .field-name-field-titel-bezeichnung{
      float:none;
      font-weight:$font_weight_bold;
      @include media-breakpoint-up(md){
        width:35%;
      }
      .field-item::after{
        content:":";
      }
    }
    .field-name-field-text-value{
      float:none;
      @include media-breakpoint-up(md){
        width:65%;
      }
      ul{
        padding-left:15px;
      }
    }
  }
}

// =============================================================================
//  Tokens
// =============================================================================

//  Kontakt
.contact-container .item-content {
  line-height: 20px;
  vertical-align: middle;
}
.contact-container .item {
  display: block;
  margin-bottom: .4em;
}
.contact-container br{ display:none; } // BR is needed for HTML Mails

// =============================================================================
//  Tables
// =============================================================================

table{
  &.sticky-header {
    z-index: 10;
  }
}

td.active {
  background-color: lighten($grey-light, 2%);
}

// =============================================================================
//  Views Styling
// =============================================================================
// Empty
.view-empty{
  @include box-style;
  font-size: .9em;
  padding: 2em;
  text-align: center;
}

// Header & Footer
.view-header{
  margin-bottom:$grid-vertical;
}
.view-footer{
  margin-top:$grid-vertical;
}

// Attachements
.attachment-before{
  margin-bottom:$grid-vertical;
}
.attachment-after{
  margin-top:$grid-vertical;
}

// Row
.views-row{
  margin-bottom:$grid-vertical / 2;
  @include clearfix-after;
  &.cutline-bottom{
    &:last-child{
      border-bottom:0 none;
      margin-bottom:0;
      padding-bottom:0;
    }
  }
}

// Item List Stlyes
// Limited to views! .item-list selecot hits way to many lists
.view{
  .item-list{
    > ul{
      margin:0;
      padding:0;
      > .views-row{
        list-style:none;
      }
    }
  }
}

// Filter
.views-exposed-form{
  &__button-wrapper{ // TODO: is this warapper still exist?
    display: flex;
    justify-content: space-between;
    flex:1;
    padding-left:$thin-grid;
    min-width:100%;
    > .views-reset-button{
      padding-left:4px;
    }
  }
  // Hide submit button on auto submit filters
  .ctools-auto-submit-full-form .views-submit-button{
    display:none;
  }
}
.views-exposed-widgets{
  margin-right:-5px; // compensate right marghin of the .views-exposed-widget
  @include media-breakpoint-up(md){
    // iPhone (6s) bugfix: flex-wrap is not working - so we show the filters vertical on all small devices.
    display:flex;
    align-items:flex-start;
    flex-wrap:wrap;
    justify-content: space-between;
  }
  &::before,
  &::after{
    display:none !important;
  }
}
.views-exposed-widget{
  margin-bottom:$thin-grid;
  margin-right:5px;
  &:not(.views-submit-button):not(.views-reset-button){
    flex:1;
  }
  &.views-widget-filter-keys{
    // search fields
    min-width:150px;
  }
  // Date Filter
  &.views-widget-filter-date_filter{
    > label{
      display:none;
    }
  }
  .date-padding{
    display:block;
    .form-item{
      margin:0;
    }
  }
  // Category / hierarchical selects
  .shs-select + .shs-select{
    margin-top:.25rem;
  }
}
.views-submit-button{
  margin-left:auto;
}
// Field Types
// -- Image
.view-image{
  text-align:center;
}

// -- Read on links
.more-link,
.read-on{
  text-align:right;
  display:block;
  clear:both;
}

.more-link a,
.read-on a,
a.more-link{
  @include btn('ghost', 'default');
  @include ico('arrow-thin-right', 'inherit', $icon-size-xs);
}
a.more-link{
  float:right;
  margin-top:$space-thin-v;
}
td.read-on a{
  display:block;
  text-align:left;
  margin-bottom:.25em;
}

// Specific Views

// -- Logo / Marken Slider
.view-suemo-m-marken{
  .owl-item{
    .views-field-field-titelbild{
      a{
        display:block;
        line-height:100px;
        text-align: center;
      }
      img{
        vertical-align: middle;
        display:inline;
      }
    }
  }
}

// -- Team
.view-suemo-m-team{
  .card__title{
    font-size:1rem;
  }
}

// -- Glossar
.view-id-glossary{
  .views-row{
    clear:both;
    margin-left: $grid / 2;
  }
  .attachment{
    margin-bottom:1.5em;
    color: $grey;
  }
  .views-summary{
    position:relative;
    a{
      text-decoration:none;
      padding:3px 5px;
      display:inline-block;
      min-width:15px;
      text-align:center;
    }
    .count{
      display:none;
    }
    &:hover{
      .count{
        position:absolute;
        bottom:17px;
        right:-2px;
        padding:2px;
        border-radius:$border-radius;
        font-size: .65em;
        line-height:normal;
        display:inline;
      }
    }
    &:hover a,
    a.active{
      border-radius:$border-radius-small;
      margin-top:-3px;
      background:$highlight;
      color:$white;
    }
    .view-content{
      a{
        display:inline-block;
        &::first-letter{
          font-size:1.2em;
          color:$highlight;
        }
      }
    }
  }
}

// =============================================================================
//  EU Cookie Compliance Message
// =============================================================================

// Cookie Compliance Message
#sliding-popup.sliding-popup-bottom {
  background: $black_glass !important;
  color: $white;
  .eu-cookie-withdraw-tab{
    @include btn();
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -3px 5px rgba(0,0,0,.1);
  }
  .eu-cookie-withdraw-banner{
    background-color: transparent !important;
  }
  .popup-content {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  #popup-text {
    flex-grow: 1;
    max-width: none;
    padding-right: $grid;

    p,
    a {
      font-weight: $font_weight_normal !important;
      color: $white !important;
    }
  }

  #popup-buttons {
    display: flex;
    max-width: none;
    margin-bottom: 0;

    button {
      background: $highlight !important;
      color: $highlight-text-color !important;
      border-radius: 0 !important;
      text-shadow: none !important;
      border-color: $highlight !important;
      margin-top: 0;
      box-shadow: none;
      padding: 10px;
      + button{
        margin-left: 8px;
      }
      &:not(.agree-button) {
        font-weight: $font_weight_normal;
      }
    }
  }
}

// =============================================================================
//  Mobules
// =============================================================================

@import 'modules/eu_cookie_compliance';
@import 'modules/mytube_embed_video_cookie_compliance';
